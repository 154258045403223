/* Import Tailwind css */
@tailwind base;
@layer base {
  body {
    font-color: "white";
  }
}
@tailwind components;
@tailwind utilities;
.spin-pink {
  border-top-color: #ec4899;
  border-right-color: #ec4899;
  border-left-color: #ec4899;
}

.spin-blue {
  border-bottom-color: #3b82f6;
  border-right-color: #3b82f6;
  border-top-color: #3b82f6;
}

.spin-purple {
  border-top-color: #8b5cf6;
  border-left-color: #8b5cf6;
}
